import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'app/hooks';
import classNames from 'classnames';
import { setLocale } from 'features/intl/intlSlice';

import './LocaleSelector.scss';

type LocaleSelectorProps = {
  locale: string;
  className?: string;
};

export default function LocaleSelector(props: LocaleSelectorProps) {
  const { locale, className } = props;
  const dispatch = useAppDispatch();

  function setLoc() {
    dispatch(setLocale(locale !== 'nl' ? 'nl' : 'en'));
  }
  const loc = () => {
    return locale !== 'nl' ? (
      <FormattedMessage id="LOCALE.NL" />
    ) : (
      <FormattedMessage id="LOCALE.EN" />
    );
  };
  return (
    <div className={classNames('localeselector', className)} onClick={setLoc}>
      {loc()}
    </div>
  );
}
